import { navigate } from 'gatsby-plugin-react-intl'
import React from 'react'

import SessionTimeout from '../../assets/images/session-timeout.svg'
import { Layout } from '../../components/auth/Layout'
import { SimpleContent } from '../../components/auth/SimpleContent'
import { useScopedIntl } from '../../hooks'
import { AccountType } from '../../requests'
import { routes } from '../../routes'

const SsoFailPage = () => {
  const intlSsoFail = useScopedIntl('auth.sso_fail')

  const onSubmit = () => {
    navigate(routes.signIn(AccountType.User))
    return Promise.resolve()
  }

  return (
    <Layout accountType={AccountType.User}>
      <SimpleContent
        title={intlSsoFail('title')}
        subtitle={intlSsoFail('subtitle')}
        submit={intlSsoFail('submit')}
        Image={SessionTimeout}
        onSubmit={onSubmit}
      />
    </Layout>
  )
}

export default SsoFailPage
export const Head = () => <title>Datacapt - SSO Fail</title>
